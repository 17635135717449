import React, { useState } from "react"
import { Dialog, TextField } from "@material-ui/core"
import styled from "styled-components"
import Spacer from "../spacer"
import LOGGER from "../../lib/logger"
import { Button, TextInput, Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import { motion } from "framer-motion"
import axios from "axios"
import { colors } from "styles/colors"
import { MaskedTextField } from "./guestCheckoutModal"
const FILE_NAME = "CompleteProfileModal.js"

export default function CompleteProfileModal({
  open,
  userData,
  loading,
  ...handlers
}) {
  const { handleClose, handleOnSubmit } = handlers

  // Load fields found in the user's profile
  const [inputValues, setInputValues] = useState({
    phoneNumber: userData?.phoneNumber ?? "+1",
    firstName: userData?.firstName ?? "",
    lastName: userData?.lastName ?? "",
  })
  const handleOnChange = event => {
    const METHOD_NAME = "handleOnChange"
    LOGGER.Debug(FILE_NAME, METHOD_NAME, "invoked")
    setInputValues({ ...inputValues, [event.target.name]: event.target.value })
  }

  return (
    <Dialog open={open} style={{ margin: -20 }}>
      <Container>
        <Typography
          style={{ textAlign: "center", marginBottom: 32 }}
          variant="h3"
        >
          Complete your profile
        </Typography>
        <CheckoutInfoContainer>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <TextInput
              id="standard-basic"
              label="First Name"
              value={inputValues.firstName}
              name="firstName"
              onChange={handleOnChange}
              InputProps={{
                style: { fontFamily: "PhantomSans", fontSize: 20 },
              }}
              InputLabelProps={{
                style: { fontFamily: "PhantomSans", fontSize: 18 },
              }}
            />
            <Spacer size={20} />
            <TextInput
              id="standard-basic"
              label="Last Name"
              value={inputValues.lastName}
              name="lastName"
              onChange={handleOnChange}
              InputProps={{
                style: { fontFamily: "PhantomSans", fontSize: 20 },
              }}
              InputLabelProps={{
                style: { fontFamily: "PhantomSans", fontSize: 18 },
              }}
            />
          </motion.div>
          <Spacer size={20} />
          <MaskedTextField
            placeholder="Enter your phone number here"
            value={inputValues.phoneNumber}
            mask="+{1} 000-000-0000"
            hint="+1 868-123-4567"
            onAccept={(value, mask) =>
              setInputValues({ ...inputValues, phoneNumber: value })
            }
            id="standard-basic"
            name="phoneNumber"
            label="Phone Number"
            onChange={handleOnChange}
            InputProps={{ style: { fontFamily: "PhantomSans" } }}
          />
          <Spacer size={20} />
          <Button
            onClick={() => handleOnSubmit(inputValues)}
            disabled={
              !inputValues.firstName ||
              !inputValues.lastName ||
              inputValues.phoneNumber.length < 15
            }
            loading={loading}
          >
            Continue
          </Button>
        </CheckoutInfoContainer>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  padding: 30px;
`

const CheckoutInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const TextButton = styled.h3`
  text-align: center;
  font-size: 30px;
  color: #21262e;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
`

const PhoneLoginText = styled(TextButton)`
  font-size: 20px;
`

const ForgotPasswordText = styled(TextButton)`
  font-size: 16px;
  text-decoration: none;
  opacity: 0.9;
`
