import React, { useState } from "react"
import { Dialog, TextField } from "@material-ui/core"
import styled from "styled-components"
import Spacer from "../spacer"
import LOGGER from "../../lib/logger"
import { Button, TextInput, Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import { motion } from "framer-motion"
import axios from "axios"
import { colors } from "styles/colors"
import { MaskedTextField } from "./guestCheckoutModal"
import toast from "react-hot-toast"
import { auth, googleAuthProvider } from "lib/firebase"
const FILE_NAME = "RegisterModal.js"

export default function RegisterModal({
  open,
  guest,
  authLoading,
  ...handlers
}) {
  const {
    handleClose,
    handleGuestCheckOut,
    handleLogin,
    handleOnSubmit,
    handleOnSubmitGoogle,
  } = handlers

  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
    password2: "",
    firstName: "",
    lastName: "",
    phoneNumber: "+1",
  })
  const [loading, setLoading] = useState(false)
  const [hasAccount, setHasAccount] = useState(false)
  const [showProfileFields, setShowProfileFields] = useState(false)

  const handleEmailStatusCheck = async () => {
    setLoading(true)
    setHasAccount(false)
    try {
      const response = await axios.post(
        "/api/users/get-user-email-auth-status",
        {
          email: inputValues.email,
        }
      )

      const status = response.data.userStatus

      // If we find a user at this account, we want to ask them to log in instead of registering.
      if (status !== "no-user") {
        setHasAccount(true)
        return
      }

      setShowProfileFields(true)
    } catch (err) {
      console.log(err.message)
      alert("We're sorry, something went wrong. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  const handleOnChange = event => {
    const METHOD_NAME = "handleOnChange"
    LOGGER.Debug(FILE_NAME, METHOD_NAME, "invoked")
    setInputValues({ ...inputValues, [event.target.name]: event.target.value })
  }

  const validateAndSubmit = () => {
    // Valid  phone number?
    if (inputValues.phoneNumber.length < 15) {
      toast.error("Please enter a valid phone number.", { duration: 3000 })
      return
    }

    // Do the passwords match?
    if (inputValues.password !== inputValues.password2) {
      toast.error("The passwords you entered do not match.", { duration: 3000 })
      return
    }

    handleOnSubmit(inputValues)
  }

  function GoogleSignInButton() {
    const signInWithGoogle = async () => {
      const authRes = await auth
        .signInWithPopup(googleAuthProvider)
        .catch(error => {
          toast.error(
            "Something went wrong signing in with Google. Please contact us if the problem persists. " +
              error.message,
            { duration: 3000 }
          )
          console.log(error)
        })

      if (authRes.user) {
        handleOnSubmitGoogle(authRes)
      }
    }
    return (
      <GoogleButton onClick={signInWithGoogle}>
        <img src="/google_logo.png" />
        Continue with Google
      </GoogleButton>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{ margin: -20 }}
      maxWidth="xs"
      fullWidth
    >
      <Container>
        <Title>Register to continue</Title>
        <CheckoutInfoContainer>
          <TextInput
            id="standard-basic"
            name="email"
            placeholder="Enter your email"
            label="Email"
            type="email"
            onChange={handleOnChange}
          />
          {hasAccount && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Typography variant="body" color="#cf3917">
                You have an account already! Sign in.
              </Typography>
            </motion.div>
          )}
          {showProfileFields && (
            <>
              <Spacer size={20} />
              <MaskedTextField
                placeholder="Enter your phone number here"
                value={inputValues.phoneNumber}
                mask="+{1} 000-000-0000"
                hint="+1 868-123-4567"
                onAccept={(value, mask) =>
                  setInputValues({ ...inputValues, phoneNumber: value })
                }
                id="standard-basic"
                name="phoneNumber"
                label="Phone Number"
                onChange={handleOnChange}
                InputProps={{
                  style: { fontFamily: "PhantomSans", width: "100%" },
                }}
              />
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Spacer size={20} />
                <TextInput
                  id="standard-basic"
                  label="First Name"
                  placeholder="Enter your first name"
                  value={inputValues.firstName}
                  name="firstName"
                  onChange={handleOnChange}
                />
                <Spacer size={20} />
                <TextInput
                  id="standard-basic"
                  label="Last Name"
                  value={inputValues.lastName}
                  name="lastName"
                  placeholder="Enter your last name"
                  onChange={handleOnChange}
                />
                <Spacer size={20} />
                <TextInput
                  id="standard-basic"
                  label="Password"
                  value={inputValues.password}
                  placeholder="Enter a password"
                  name="password"
                  onChange={handleOnChange}
                  type="password"
                />
                <Spacer size={20} />
                <TextInput
                  id="standard-basic"
                  label="Confirm Password"
                  value={inputValues.password2}
                  placeholder="Enter a password"
                  name="password2"
                  onChange={handleOnChange}
                  type="password"
                />
              </motion.div>
            </>
          )}
          <Spacer size={20} />
          <Button
            onClick={() =>
              showProfileFields
                ? validateAndSubmit(inputValues)
                : handleEmailStatusCheck()
            }
            disabled={
              !inputValues.email ||
              (showProfileFields &&
                (!inputValues.firstName ||
                  (showProfileFields && !inputValues.lastName) ||
                  !inputValues.firstName ||
                  !inputValues.password))
            }
            loading={loading || authLoading}
          >
            {showProfileFields ? "Register and continue" : "Continue"}
          </Button>
          {/* <Spacer size={20} />
          <GoogleSignInButton /> */}
          <Spacer size={30} />
          <Typography style={{ textAlign: "center" }} variant="body">
            Already have an account?{" "}
            <span
              onClick={handleLogin}
              style={{
                color: colors.businessPrimary,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Sign in
            </span>
          </Typography>
          {/* <PhoneLoginText onClick={handleDisplayPhoneLogin}>
            Sign in with your phone number
          </PhoneLoginText> */}
          <Spacer size={15} />
          {guest !== false && (
            <GuestLoginText onClick={handleGuestCheckOut}>
              Check out as a guest
            </GuestLoginText>
          )}
          {/* <Spacer size={30} /> */}
          {/* <ForgotPasswordText onClick={handleDisplayPasswordReset}>
            Forgot your password?
          </ForgotPasswordText> */}
        </CheckoutInfoContainer>
      </Container>
    </Dialog>
  )
}
const Container = styled.div`
  padding: 30px;
`
const Title = styled.h3`
  margin-bottom: 20px;
  text-align: center;
  color: #21262e;
  font-size: 30px;
`
const CheckoutInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const TextButton = styled.h3`
  text-align: center;
  font-size: 30px;
  color: #21262e;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
`
const GoogleButton = styled.button`
  background: transparent;
  // font-family: "Gilmer";
  font-weight: 500;
  font-size: 18px;
  color: black;
  text-align: center;
  border-radius: 50px;
  width: 100%;
  justify-content: center;
  padding: 14px 25px;
  border: 1px solid #707070;
  outline: none;
  cursor: pointer;
  transition: 0.25s ease;
  align-items: center;
  display: flex;
  flex-direction: row;
  > img {
    width: 20px;
    margin: 0 10px 0 0;
  }
  :hover {
    filter: brightness(1.3);
  }
  :focus {
    outline: none;
  }
  :disabled {
    background: #707070;
  }
`
export const GuestLoginText = styled(TextButton)`
  font-size: 19px;
  opacity: 0.9;
  font-weight: 400;
  /* text-decoration: none; */
`
