import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Menu, Divider, UnstyledButton } from "@mantine/core"
import toast from "react-hot-toast"
import { CgProfile } from "react-icons/cg"
import { HiUserCircle } from "react-icons/hi"
import { RiMapPin2Fill } from "react-icons/ri"
import { IoMdCard } from "react-icons/io"
import firebase, { auth } from "lib/firebase"
import { useRouter } from "next/router"
import { useGlobalDisptach } from "../context/GlobalContextProvider"
import * as actions from "../context/actionTypes"

export default function ProfileHUD({}) {
  const dispatch = useGlobalDisptach()
  const router = useRouter()

  const promptLogout = () => {
    const confirmation = window.confirm("Are you sure?")
    if (confirmation) {
      handleLogout()
    }
  }
  const handleLogout = () => {
    auth.signOut()
    dispatch({
      type: actions.UPDATE_USER_AUTH,
      item: {},
    })
    dispatch({
      type: actions.UPDATE_USER_DATA,
      item: {},
    })
    toast.success("You have been logged out.")
    router.push("/")
  }
  return (
    <Container>
      <Menu
        styles={{
          itemLabel: { fontFamily: "PhantomSans", fontSize: "0.95rem" },
        }}
        control={
          <UnstyledButton type="button">
            <HiUserCircle color="white" size={26} />
          </UnstyledButton>
        }
      >
        <Menu.Label>Your profile</Menu.Label>
        <Menu.Item
          onClick={() => router.push("/profile?tab=profile")}
          icon={<CgProfile size={18} />}
        >
          Account Information
        </Menu.Item>
        <Menu.Item
          onClick={() => router.push("/profile?tab=addresses")}
          icon={<RiMapPin2Fill size={18} />}
        >
          Addresses
        </Menu.Item>
        {/*
        <Menu.Item
          onClick={() => router.push("/profile?tab=payment")}
          icon={<IoMdCard size={18} />}
        >
          Payment Methods
        </Menu.Item>
      */}
        <Divider />
        {/* <Menu.Label>Danger zone</Menu.Label> */}
        <Menu.Item onClick={promptLogout} color="red">
          Log out
        </Menu.Item>
      </Menu>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`
