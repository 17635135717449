const LOGGER =  {
	Debug: (file, method, message) => {
		console.group(`%c ${file}, ${method}`,'color: #009ACD');
		console.log(message);
		console.groupEnd();
 },
	Info: (file, method, message) => {
		console.group(`%c ${file}, ${method}`,'color: #009ACD');
		console.log(message)
		console.groupEnd();
 }
}

export default LOGGER;


