import React, { useState, useRef } from "react"
import { Dialog, TextField } from "@material-ui/core"
import styled from "styled-components"
import Spacer from "../spacer"
import LOGGER from "../../lib/logger"
import firebase, { auth } from "lib/firebase"
import { IMaskMixin } from "react-imask"
import CircularProgress from "@material-ui/core/CircularProgress"
import axios from "axios"
import { Flex } from "components/layout/shared"
import { formatPhoneForFirebase } from "lib/utils"
import { Button, TextInput, Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import { GuestLoginText } from "./registerModal"
import { MaskedTextField } from "./guestCheckoutModal"
import { colors } from "styles/colors"

const FILE_NAME = "PhoneLoginModal.js"

export default function PhoneLoginModal({
  open,
  handleClose,
  guest,
  handleLoginWithPhoneNumber,
  ...handlers
}) {
  const { handleEmailLogin, handleGuestCheckOut } = handlers

  const element = useRef(null)

  const [confirmationResult, setConfirmationResult] = useState(null)
  const [showProfileFields, setShowProfileFields] = useState(false)
  const [loadingRecaptcha, setLoadingRecaptcha] = useState(false)
  const [recaptcha, setRecaptcha] = useState(null)

  const [inputValues, setInputValues] = useState({
    phone: "+1",
    code: "",
  })

  const setupCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container"
    )
  }
  const runRecaptchaVerifier = () => {
    return new Promise((resolve, reject) => {
      if (!recaptcha) {
        const verifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container"
        )

        // const verifier = new firebase.auth.RecaptchaVerifier("login-button", {
        //   size: "invisible",
        //   callback: async response => {
        //     // reCAPTCHA solved, allow signInWithPhoneNumber.
        //     setConfirmationResult(
        //       await auth.signInWithPhoneNumber(inputValues.phone, verifier)
        //     )
        //   },
        // })

        verifier
          .verify()
          .then(() => {
            setRecaptcha(verifier)
            return resolve(verifier)
          })
          .catch(error => {
            setLoadingRecaptcha(false)
            reject(error)
          })
      } else {
        return resolve(recaptcha)
      }
    })
  }

  const handleOnChange = event => {
    const METHOD_NAME = "handleOnChange"
    LOGGER.Debug(FILE_NAME, METHOD_NAME, "invoked")
    setInputValues({ ...inputValues, [event.target.name]: event.target.value })
  }

  const beginSignInWithPhoneNumber = async (bypass = false) => {
    const METHOD_NAME = "beginSignInWithPhoneNumber"
    LOGGER.Debug(FILE_NAME, METHOD_NAME, "invoked")
    LOGGER.Debug(FILE_NAME, METHOD_NAME, inputValues.phone)
    // Check to see the status of this phone number
    const response = await axios.post(
      "/api/users/get-user-phone-number-status",
      {
        phoneNumber: inputValues.phone,
      }
    )

    const status = response.data.userStatus

    try {
      if (status === "verified" || bypass) {
        // They have a user account, let's log in
        setupCaptcha()
        const verifier = window.recaptchaVerifier
        const formattedPhone = formatPhoneForFirebase(inputValues.phone)
        const confirmationResult = await auth.signInWithPhoneNumber(
          formattedPhone,
          verifier
        )

        setConfirmationResult(confirmationResult)
        return
      }
      if (status === "incomplete-profile") {
        // An account exists, but we haven't added them to the users collection yet
        // Let's do that now, show the profile information
        setShowProfileFields(true)
        return
      }
      if (status === "no-user") {
        // What do we do? treat them as though they're a new user?
        setShowProfileFields(true)
        return
      }
    } catch (err) {
      alert(err.message)
      setLoadingRecaptcha(false)
    }
  }

  const verifyCode = async () => {
    const METHOD_NAME = "verifyCode"
    LOGGER.Debug(FILE_NAME, METHOD_NAME, "invoked")
    LOGGER.Debug(FILE_NAME, METHOD_NAME, inputValues.code)
    try {
      const result = await confirmationResult.confirm(inputValues.code)
      // Successfully logged in, let's save user information to the database
      if (showProfileFields) {
        await saveUserToDB(result.user.uid)
      }
      console.log("Next")
      handleLoginWithPhoneNumber(result.user)
    } catch (err) {
      console.log(err)
      if (err.code === "auth/invalid-verification-code") {
        alert("Invalid code. Please try again.")
        return
      }
      alert(err.message)
    }
  }

  const handleOnSubmit = (bypass = false) => {
    if (!confirmationResult) return beginSignInWithPhoneNumber(bypass)

    return verifyCode()
  }

  const saveUserToDB = async id => {
    try {
      await axios.post("/api/users/update-user", {
        id: id,
        data: {
          phoneNumber: inputValues.phone,
          firstName: inputValues.firstName,
          lastName: inputValues.lastName,
          email: inputValues.email,
          // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          id: id,
        },
      })
      await auth?.currentUser?.reload()

      return true
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <Dialog
      style={{ margin: -20 }}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <Container>
        <Title>
          {confirmationResult
            ? "Enter Verification Code"
            : showProfileFields
            ? "Complete your profile"
            : "Log in to Unqueue"}
        </Title>
        <CheckoutInfoContainer>
          {confirmationResult ? (
            <TextInput
              id="standard-basic"
              name="code"
              placeholder="0 0 0 0 0 0"
              value={inputValues.code}
              type="number"
              label="Verification Code"
              onChange={handleOnChange}
              helperText={
                confirmationResult
                  ? "We've sent a 6 digit code to the phone number you entered"
                  : "You’ll get updates in-app and via email."
              }
            />
          ) : (
            <>
              <MaskedTextField
                placeholder="Enter your phone number here"
                value={inputValues.phone}
                mask="+{1} 000-000-0000"
                hint="+1 868-123-4567"
                onAccept={(value, mask) =>
                  setInputValues({ ...inputValues, phone: value })
                }
                id="standard-basic"
                name="phone"
                label="Phone Number"
                onChange={handleOnChange}
                type="tel"
                InputProps={{ style: { fontFamily: "PhantomSans" } }}
              />
              {showProfileFields && (
                <>
                  <Spacer size={20} />
                  <TextInput
                    id="standard-basic"
                    label="First Name"
                    value={inputValues.firstName}
                    name="firstName"
                    onChange={handleOnChange}
                  />
                  <Spacer size={20} />
                  <TextInput
                    id="standard-basic"
                    label="Last Name"
                    value={inputValues.lastName}
                    name="lastName"
                    onChange={handleOnChange}
                  />
                  <Spacer size={20} />
                  <TextInput
                    id="standard-basic"
                    label="Email Address"
                    value={inputValues.email}
                    name="email"
                    type="email"
                    onChange={handleOnChange}
                  />
                </>
              )}
            </>
          )}
          <Spacer size={20} />

          <Button
            id="login-button"
            disabled={
              inputValues.phone.length < 15 ||
              (showProfileFields &&
                (!inputValues.firstName ||
                  !inputValues.lastName ||
                  !inputValues.email))
            }
            onClick={() => handleOnSubmit(showProfileFields)}
          >
            {/* {showProfileFields ? "Continue" : "Log in"} */}
            Continue
          </Button>
          {loadingRecaptcha ? (
            <CircularProgress
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
          ) : null}
          {!confirmationResult ? (
            <Flex
              style={{ marginTop: 10 }}
              id="recaptcha-container"
              ref={element}
            ></Flex>
          ) : null}
          {!confirmationResult ? (
            <>
              <Spacer size={30} />
              <Typography style={{ textAlign: "center" }} variant="body">
                Prefer email?{" "}
                <span
                  onClick={handleEmailLogin}
                  style={{
                    color: colors.businessPrimary,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Sign up with email
                </span>
              </Typography>
              {/* <EmailLoginText onClick={handleEmailLogin}>
                Log in with Email
              </EmailLoginText> */}
              <Spacer size={15} />
              {guest !== false && <GuestLoginText onClick={handleGuestCheckOut}>
                Guest Checkout
              </GuestLoginText>}

              <Spacer size={30} />
            </>
          ) : (
            <>
              <Spacer size={30} />

              <GuestLoginText
                onClick={() => {
                  setShowProfileFields(false)
                  setConfirmationResult(null)
                  setRecaptcha(null)
                }}
              >
                Go back
              </GuestLoginText>
            </>
          )}
        </CheckoutInfoContainer>
      </Container>
    </Dialog>
  )
}
const Container = styled.div`
  padding: 30px;
`
const Title = styled.h3`
  margin-bottom: 20px;
  text-align: center;
  color: #21262e;
  font-size: 30px;
`
const CheckoutInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

// const Button = styled(MainButton)`
//   border-radius: 30px;
// `
const TextButton = styled.h3`
  text-align: center;
  font-size: 30px;
  color: #21262e;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in;

  :hover {
    transform: scale(1.1);
  }
`

const EmailLoginText = styled(TextButton)`
  font-size: 22px;
`

const ForgotPasswordText = styled(TextButton)`
  font-size: 16px;
  text-decoration: none;
  opacity: 0.9;
`

const SmallText = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: #707070;
`
