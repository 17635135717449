import React, { useState } from "react"
import { CircularProgress, Dialog, TextField } from "@material-ui/core"
import styled from "styled-components"
import Spacer from "../spacer"
import { Button, TextInput } from "@unqueue-dev-inc/unqueue-ui-web"

export default function PasswordResetModal({
  open,
  handleClose,
  handleSubmit,
  loading,
}) {
  const [inputValues, setInputValues] = useState({
    email: "",
  })

  const handleChange = e => {
    setInputValues({ [e.target.name]: e.target.value })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Container>
        <Title>Password Reset</Title>
        <CheckoutInfoContainer>
          <TextInput
            id="standard-basic"
            label="Email Address"
            value={inputValues.email}
            name="email"
            placeholder="Enter your email address"
            onChange={handleChange}
          />

          <Spacer size={20} />
          <Button
            onClick={() => handleSubmit(inputValues)}
            loading={loading}
            disabled={!inputValues.email || loading}
          >
            Reset Password
          </Button>
        </CheckoutInfoContainer>
      </Container>
    </Dialog>
  )
}
const Container = styled.div`
  padding: 30px;
`
const Title = styled.h3`
  margin-bottom: 20px;
  text-align: center;
  color: #21262e;
  font-size: 30px;
`
const CheckoutInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextButton = styled.h3`
  text-align: center;
  font-size: 30px;
  color: #21262e;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in;

  :hover {
    transform: scale(1.1);
  }
`

const SmallText = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: #707070;
`
