import React, { useState } from "react"
import { Dialog, TextField } from "@material-ui/core"
import styled from "styled-components"
import Spacer from "../spacer"
import { IMaskMixin } from "react-imask"
import { TextInput, Button } from "@unqueue-dev-inc/unqueue-ui-web"
import { colors } from "styles/colors"

export const MaskedTextField = IMaskMixin(({ inputRef, ...props }) => (
  <TextField
    {...props}
    InputProps={{
      style: { fontSize: 20, fontFamily: "PhantomSans", marginTop: 25 },
    }}
    InputLabelProps={{
      style: {
        fontSize: 26,
        fontWeight: 500,
        color: colors.base,
        fontFamily: "PhantomSans",
      },
    }}
    inputRef={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
  />
))

export default function GuestCheckOutModal({
  open,
  handleClose,
  handleSubmit,
  ...otherProps
}) {
  const [inputValues, setInputValues] = useState({
    fullName: "",
    email: "",
    phoneNumber: "+1",
  })

  const handleChange = e => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })
  }

  return (
    <Dialog open={open} onClose={handleClose} style={{ margin: -20 }}>
      <Container>
        <Title>Guest Checkout</Title>
        <CheckoutInfoContainer>
          <TextInput
            label="Full Name"
            value={inputValues.fullName}
            name="fullName"
            placeholder="Enter your full name"
            onChange={handleChange}
          />
          <Spacer size={20} />
          <TextInput
            placeholder="Enter your email address"
            label="Email Address"
            value={inputValues.email}
            name="email"
            type="email"
            onChange={handleChange}
          />
          <Spacer size={20} />
          <MaskedTextField
            id="standard-basic"
            label="Phone Number"
            value={inputValues.phoneNumber}
            onAccept={(value, mask) =>
              setInputValues({ ...inputValues, phoneNumber: value })
            }
            name="phoneNumber"
            onChange={handleChange}
            mask="+{1} 000-000-0000"
            hint="+1 868-123-4567"
          />
          <Spacer size={20} />
          <SmallText>
            You’ll get updates on your order via text message and email.
          </SmallText>
          <Spacer size={30} />
          <Button
            onClick={() => handleSubmit(inputValues)}
            disabled={
              !inputValues.fullName ||
              !inputValues.email ||
              inputValues.phoneNumber.length < 15
            }
          >
            Confirm
          </Button>
        </CheckoutInfoContainer>
      </Container>
    </Dialog>
  )
}
const Container = styled.div`
  padding: 30px;
`
const Title = styled.h3`
  margin-bottom: 20px;
  text-align: center;
  color: #21262e;
  font-size: 30px;
`
const CheckoutInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextButton = styled.h3`
  text-align: center;
  font-size: 30px;
  color: #21262e;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in;

  :hover {
    transform: scale(1.1);
  }
`

const SmallText = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: #707070;
`
