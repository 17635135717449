import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Link from "next/link"
import { colors } from "../../styles/colors"
import ProfileHUD from "components/profile-hud"
import Spacer from "components/spacer"
import { UnstyledButton } from "@mantine/core"
import { HiUserCircle } from "react-icons/hi"

// import { Wrapper } from "components/layout/shared"
import CompleteProfileModal from "components/shared/completeProfileModal"
import PhoneLoginModal from "components/shared/phoneLoginModal"
import LoginModal from "components/shared/loginModal"
import RegisterModal from "components/shared/registerModal"
import PasswordResetModal from "components/shared/passwordResetModal"

import {
  useGlobalState,
  useGlobalDisptach,
} from "../../context/GlobalContextProvider"

import * as actions from "../../context/actionTypes"

import {
  signInWithEmail,
  auth,
  firestore,
  registerWithEmail,
} from "../../lib/firebase"
import { isEmail } from "../../lib/utils"
import toast from "react-hot-toast"
import axios from "axios"
import firebase from "../../lib/firebase"

const Header = () => {
  const state = useGlobalState()
  const dispatch = useGlobalDisptach()

  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [registerModalOpen, setRegisterModalOpen] = useState(false)
  const [phoneLoginModal, setPhoneLoginModal] = useState(false)
  const [completeProfileModalOpen, setCompleteProfileModalOpen] =
    useState(false)
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false)
  const [authLoading, setAuthLoading] = useState(false)
  const [passwordResetLoader, setPasswordResetLoader] = useState(false)
  const [showChild, setShowChild] = useState(false)

  useEffect(() => {
    setShowChild(true)
  }, [])

  if (!showChild) {
    return null
  }

  const { userAuth, userData } = state

  const handleEmailRegister = () => {
    setPhoneLoginModal(false)
    setLoginModalOpen(false)
    setRegisterModalOpen(true)
  }
  const handleEmailLogin = () => {
    setRegisterModalOpen(false)
    setPhoneLoginModal(false)
    setLoginModalOpen(true)
  }

  const handleDisplayPhoneLogin = () => {
    setLoginModalOpen(false)
    setPhoneLoginModal(true)
  }

  const handleDisplayPasswordReset = () => {
    setLoginModalOpen(false)
    setPasswordResetModalOpen(true)
  }

  const handleOnLoginFormSubmit = formData => {
    const METHOD_NAME = "handleOnLoginFormSubmit"
    // LOGGER.Debug(FILENAME, METHOD_NAME, { formData })

    let { email, password } = formData

    isEmail(formData.email)
      ? loginWithEmail(email, password)
      : toast.error("Please enter valid email address")
  }

  const handleOnSubmitWithGoogle = async userCredential => {
    // Signed in
    var user = userCredential.user

    // Save their info
    if (userCredential?.additionalUserInfo?.profile) {
      console.log(userCredential.additionalUserInfo)
      const ref = firestore.collection("users").doc(userCredential.user.uid)
      await ref.set({
        firstName:
          userCredential?.additionalUserInfo?.profile?.given_name ??
          userCredential?.additionalUserInfo?.profile?.name,
        lastName: userCredential?.additionalUserInfo?.profile?.family_name,
        email: userCredential?.additionalUserInfo?.profile?.email,
        providerId: userCredential?.additionalUserInfo?.providerId,
        ...(userCredential?.additionalUserInfo?.isNewUser && {
          createdAt: new Date(),
        }),
      })
    }
    // Check the user's profile to ensure that their profile is complete
    const response = await axios.post("/api/users/get-user", {
      id: userCredential.user.uid,
    })
    const userData = response.data
    dispatch({
      type: actions.UPDATE_USER_AUTH,
      item: userCredential.user,
    })
    dispatch({
      type: actions.UPDATE_USER_DATA,
      item: { id: user.uid, ...userData },
    })
    if (!userData.firstName || !userData.lastName || !userData.phoneNumber) {
      // Show the complete profile modal and let that handle things from there
      setRegisterModalOpen(false)
      setCompleteProfileModalOpen(true)
      return
    }
  }

  const handleOnRegisterFormSubmit = formData => {
    const METHOD_NAME = "handleOnLoginFormSubmit"
    // LOGGER.Debug(FILENAME, METHOD_NAME, { formData })

    const { email, password, firstName, lastName, phoneNumber } = formData

    isEmail(formData.email)
      ? registerWithEmailFn(email, password, firstName, lastName, phoneNumber)
      : toast.error("Please enter valid email address")
  }

  const loginWithEmail = async (email, password) => {
    const METHOD_NAME = "loginWithEmail"

    // LOGGER.Debug(FILENAME, METHOD_NAME, "invoked")
    try {
      setAuthLoading(true)
      const userCredential = await signInWithEmail(email, password)
      // Signed in
      var user = userCredential.user

      // LOGGER.Debug(FILENAME, METHOD_NAME, { userCredential, user })
      console.log(user.uid)
      // Check the user's profile to ensure that their profile is complete
      const response = await axios.post("/api/users/get-user", {
        id: user.uid,
        // id: "2DqbNJxZYTRqtom8MuposiWaeVB3",
      })
      const userData = response.data
      dispatch({
        type: actions.UPDATE_USER_AUTH,
        item: user,
      })
      dispatch({
        type: actions.UPDATE_USER_DATA,
        item: { email: user.email, id: user.uid, ...userData },
      })
      if (!userData.firstName || !userData.lastName || !userData.phoneNumber) {
        // Show the complete profile modal and let that handle things from there
        setLoginModalOpen(false)
        setCompleteProfileModalOpen(true)
        return
      }

      // We got a user successfully!
      setLoginModalOpen(false)

      toast.success("You logged in successfully")
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        toast.error("We didn't find a user account with this email address")
        return
      }
      toast.error(`Something went wrong signing in. ${err.message}`)
    } finally {
      setAuthLoading(false)
    }
  }

  const registerWithEmailFn = async (
    email,
    password,
    firstName,
    lastName,
    phoneNumber
  ) => {
    const METHOD_NAME = "registerWithEmail"

    // LOGGER.Debug(FILENAME, METHOD_NAME, "invoked")
    try {
      setAuthLoading(true)
      const userCredential = await registerWithEmail(email, password)
      // Signed in
      var user = userCredential.user
      // LOGGER.Debug(FILENAME, METHOD_NAME, { userCredential, user })

      dispatch({
        type: actions.UPDATE_USER_AUTH,
        item: user,
      })
      dispatch({
        type: actions.UPDATE_USER_DATA,
        item: {
          email: user.email,
          id: user.uid,
          firstName,
          lastName,
          phoneNumber,
        },
      })

      // We got a user successfully!
      setRegisterModalOpen(false)
      handleCompleteProfile(
        { email: user.email, id: user.uid, firstName, lastName, phoneNumber },
        true
      )
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        toast.error("We didn't find a user account with this email address")
        return
      }
      toast.error(`Something went wrong creating an account. ${err.message}`)
    } finally {
      setAuthLoading(false)
    }
  }

  const handleCompleteProfile = async (formData, newProfile) => {
    const METHOD_NAME = "handleCompleteProfile"
    // LOGGER.Debug(FILENAME, METHOD_NAME, { formData })
    try {
      // Save the data to the user's profile
      setAuthLoading(true)
      await firestore
        .collection("users")
        .doc(userAuth.uid ?? formData.id)
        .set(
          {
            ...formData,
            email: formData?.email ?? userAuth.email,
            ...(userAuth && {
              id: userAuth?.uid ?? formData?.id,
            }),
            ...(newProfile && {
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            }),
          },
          { merge: true }
        )
      dispatch({
        type: actions.UPDATE_USER_DATA,
        item: { ...formData, email: userAuth.email, id: userAuth.uid },
      })
      // Update the current user with this new information
      const currentUser = await auth.currentUser
      await currentUser.updateProfile({
        displayName: `${formData.firstName} ${formData.lastName}`,
      })
      await currentUser.reload()
      dispatch({
        type: actions.UPDATE_USER_AUTH,
        item: currentUser,
      })
      // Finish up
      // Thanks for signing up!
      toast.success("Thanks for signing up!")
      setCompleteProfileModalOpen(false)
      navigate("/profile")
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong." + err.message)
    } finally {
      setAuthLoading(false)
    }
  }

  const loginWithPhoneNumber = user => {
    const METHOD_NAME = "loginWithPhoneNumber"

    // LOGGER.Debug(FILENAME, METHOD_NAME, "invoked")

    dispatch({
      type: actions.UPDATE_USER_AUTH,
      item: user,
    })

    setPhoneLoginModal(false)
  }

  const handlePassWordResetSubmit = ({ email }) => {
    // LOGGER.Debug(FILENAME, "handlePassWordResetSubmit", { email })

    setPasswordResetLoader(true)

    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        // Email sent.
        setPasswordResetLoader(false)
        alert("Password Reset Email sent")
        setPasswordResetModalOpen(false)
      })
      .catch(function (error) {
        // An error happened.
        console.log(error)
        if (error.code === "auth/user-not-found") {
          toast.error("We didn't find a user account with this email address")
          return
        }
        toast.error(error.message)
        setPasswordResetModalOpen(false)
      })
      .finally(() => {
        setPasswordResetLoader(false)
      })
  }
  return (
    <>
      <Wrapper>
        <AnnouncementContainer>
          We have made the very difficult decision to discontinue the Unqueue
          for Business platform. This change will come into effect on Sunday 30
          June 2024,{" "}
          <a
            style={{ color: "white", textDecoration: "underline" }}
            rel="noopener noreferrer"
            href="https://support.unqueue.app/unqueue-shutdown-faq-guide-for-businesses-and-shoppers/mtvkaGeEPkaP2x6ufzhmRU/unqueue-shutdown-faq-guide-for-businesses-and-shoppers/77X1zAUNn4HPYnZBNNnj5A"
            target="_blank"
          >
            read more here
          </a>
          .
        </AnnouncementContainer>
        <Nav>
          <Link href="/">
            <LogoImg src={"/unqueuewordmarklogo.png"} />
          </Link>
          <ActionsSection>
            {!userAuth.hasOwnProperty("uid") ? (
              <UnstyledButton
                type="button"
                onClick={() => setRegisterModalOpen(true)}
              >
                <HiUserCircle color="white" size={26} />
              </UnstyledButton>
            ) : (
              <ProfileHUD />
            )}
            <Spacer axis="horizontal" size={8} />
            <Link href="/cart">
              <span style={{ cursor: "pointer" }}>
                Cart ({state.shoppingCart.length})
              </span>
            </Link>
          </ActionsSection>
        </Nav>
      </Wrapper>
      {/* DIALOGS */}
      {completeProfileModalOpen && (
        <CompleteProfileModal
          open={completeProfileModalOpen}
          handleOnSubmit={handleCompleteProfile}
          userData={userData}
          loading={authLoading}
          // handleClose={() => setRegisterModalOpen(false)}
        />
      )}
      <RegisterModal
        guest={false}
        open={registerModalOpen}
        authLoading={authLoading}
        handleClose={() => setRegisterModalOpen(false)}
        handleOnSubmit={handleOnRegisterFormSubmit}
        handleOnSubmitGoogle={handleOnSubmitWithGoogle}
        handleLogin={handleEmailLogin}
        handleDisplayPasswordReset={handleDisplayPasswordReset}
        handleDisplayPhoneLogin={handleDisplayPhoneLogin}
      />
      <LoginModal
        guest={false}
        open={loginModalOpen}
        loading={authLoading}
        handleClose={() => setLoginModalOpen(false)}
        handleDisplayPasswordReset={handleDisplayPasswordReset}
        handleRegister={handleEmailRegister}
        handleDisplayPhoneLogin={handleDisplayPhoneLogin}
        handleOnSubmit={handleOnLoginFormSubmit}
      />
      {
        <PhoneLoginModal
          guest={false}
          open={phoneLoginModal}
          handleClose={() => setPhoneLoginModal(false)}
          handleLoginWithPhoneNumber={loginWithPhoneNumber}
          handleEmailLogin={handleEmailLogin}
        />
      }

      <PasswordResetModal
        guest={false}
        open={passwordResetModalOpen}
        handleClose={() => setPasswordResetModalOpen(false)}
        handleSubmit={handlePassWordResetSubmit}
        loading={passwordResetLoader}
      />
    </>
  )
}

export default Header

const Wrapper = styled.div`
  background: ${colors.base};
  position: sticky;
  top: 0;
  z-index: 99;
`

const AnnouncementContainer = styled.div`
  padding: 10px;
  background: ${colors.businessPrimary};
  color: white;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
`

const Nav = styled.nav`
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 530px) {
    padding: 15px;
  }
`

const LogoImg = styled.img`
  height: 21px;
  cursor: pointer;

  @media (max-width: 530px) {
    height: auto;
    max-width: 80px;
  }
`

const ActionsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-self: flex-end;
  margin-right: 20px;
  font-size: 20px;
  color: #fff;

  > a {
    color: #fff;
  }
`
