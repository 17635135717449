import React, { useState } from "react"
import { Dialog, TextField } from "@material-ui/core"
import styled from "styled-components"
import Spacer from "../spacer"
import LOGGER from "../../lib/logger"
import { Button, TextInput, Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import { motion } from "framer-motion"
import axios from "axios"
import { colors } from "styles/colors"
import { GuestLoginText } from "./registerModal"
const FILE_NAME = "loginModal.js"

export default function LoginModal({ open, guest, loading, ...handlers }) {
  const {
    handleClose,
    handleDisplayPhoneLogin,
    handleGuestCheckOut,
    handleOnSubmit,
    handleDisplayPasswordReset,
    handleRegister,
  } = handlers

  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  })
  const [hasAccount, setHasAccount] = useState(false)
  const [showProfileFields, setShowProfileFields] = useState(false)

  const handleEmailStatusCheck = async () => {
    handleOnSubmit(inputValues)
    return
    setLoading(true)
    try {
      const response = await axios.post(
        "/api/users/get-user-email-auth-status",
        {
          email: inputValues.email,
        }
      )

      const status = response.data.userStatus
      // Do we need to ask them to complete their profile?
      if (status === "incomplete-profile") {
        setShowProfileFields(true)
        return
      }

      // Proceed with login...
    } catch (err) {
      console.log(err.message)
      alert("We're sorry, something went wrong. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  const handleOnChange = event => {
    const METHOD_NAME = "handleOnChange"
    LOGGER.Debug(FILE_NAME, METHOD_NAME, "invoked")
    setInputValues({ ...inputValues, [event.target.name]: event.target.value })
  }

  return (
    <Dialog style={{ margin: -20 }} open={open} onClose={handleClose}>
      <Container>
        <Typography
          style={{ textAlign: "center", marginBottom: 32 }}
          variant="h3"
        >
          Sign in to Unqueue
        </Typography>
        <CheckoutInfoContainer>
          <TextInput
            id="standard-basic"
            name="email"
            placeholder="Enter your email"
            label="Email"
            type="email"
            onChange={handleOnChange}
          />
          <Spacer size={20} />
          <TextInput
            id="standard-basic"
            name="password"
            placeholder="Enter your password"
            label="Password"
            onChange={handleOnChange}
            type="password"
          />
          <Spacer size={20} />
          {showProfileFields && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <TextInput
                id="standard-basic"
                label="First Name"
                value={inputValues.firstName}
                name="firstName"
                onChange={handleOnChange}
                InputProps={{
                  style: { fontFamily: "PhantomSans", fontSize: 20 },
                }}
                InputLabelProps={{
                  style: { fontFamily: "PhantomSans", fontSize: 18 },
                }}
              />
              <Spacer size={20} />
              <TextInput
                id="standard-basic"
                label="Last Name"
                value={inputValues.lastName}
                name="lastName"
                onChange={handleOnChange}
                InputProps={{
                  style: { fontFamily: "PhantomSans", fontSize: 20 },
                }}
                InputLabelProps={{
                  style: { fontFamily: "PhantomSans", fontSize: 18 },
                }}
              />
              <Spacer size={20} />
              <TextInput
                id="standard-basic"
                label="Password"
                value={inputValues.password}
                name="email"
                onChange={handleOnChange}
                type="password"
              />
            </motion.div>
          )}
          <Spacer size={20} />
          <Button
            onClick={() => handleEmailStatusCheck(inputValues)}
            disabled={!inputValues.email}
            loading={loading}
          >
            Continue
          </Button>
          <Spacer size={30} />
          <PhoneLoginText onClick={handleDisplayPhoneLogin}>
            Sign in with your phone number
          </PhoneLoginText>
          <Spacer size={10} />
          <Typography style={{ textAlign: "center" }} variant="body">
            Don’t have an account?{" "}
            <span
              onClick={handleRegister}
              style={{
                color: colors.businessPrimary,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Sign up for free
            </span>
          </Typography>
          <Spacer size={30} />
          {guest !== false && <GuestLoginText onClick={handleGuestCheckOut}>
            Guest Checkout
          </GuestLoginText>}
          <Spacer size={30} />
          <ForgotPasswordText onClick={handleDisplayPasswordReset}>
            Forgot your password?
          </ForgotPasswordText>
        </CheckoutInfoContainer>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  padding: 30px;
`

const CheckoutInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const TextButton = styled.h3`
  text-align: center;
  font-size: 30px;
  color: #21262e;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
`

const PhoneLoginText = styled(TextButton)`
  font-size: 20px;
`

const ForgotPasswordText = styled(TextButton)`
  font-size: 16px;
  text-decoration: none;
  opacity: 0.9;
`
